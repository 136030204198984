<template>
	<div id="main-wrapper" class="inner-pages why-page career-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>Careers</h2>
				<p>We love what we do and think you will love it too! Inclusiveness, Diversity, Innovation, and Loyalty are the reasons why our people love their work and love coming to work every day.</p>
				<br>
				<h6><strong>Join us and help us add value in everything we do!</strong></h6>
			</div>
		</div>
		<div class="section our-cult">
			<div class="wrapper2">
				<div class="img-holder wow fadeInLeft">
					<img src="assets/images/cult-img.webp" alt="AAAFx" title="AAAFx" width="517" height="694" class="fluid-img" />
				</div>
				<div class="content-holder wow fadeInRight">
					<h3>Our Culture</h3>
					<br>
					<p class="italic">No one can whistle a symphony. It takes a whole orchestra to play it. – (H.E.  Luccock)</p>
					<br>
					<p>At AAAFx we encourage you to be the better version of yourself. Express who you truly are; learn and succeed! Currently, we are employing 80+ people, and aim to further expand our team. Our goal is to create a conducive work environment that gives you a sense of fulfillment. </p>
					<br>
					<h5>When our clients <br>succeed, we succeed!</h5>
				</div>

				<div class="section testi-list">
					<div class="testi-holder wow fadeIn">
						<div class="testi">
								<h3>We are less when we don’t include everyone.
									<br>- (Stuart Milk)</h3>
								<p>We have built a cohesive and inclusive culture that embraces a wide range of employees, who have robust lists of personal achievements and immense team spirit. Inclusiveness fosters a sense of belongingness and respect towards the organization. This is the reason our people feel important and valued.  We strive to create an environment where everyone feels they are working for the same cause together- <span style="font-style:italic;">“excellent performance for a better tomorrow”.</span> </p>
						</div>
					</div>
					<div class="testi-holder wow fadeIn" data-wow-delay="0.3s">

						<div class="testi">
								<h3>Strength lies in differences, not in similarities.
									<br>– (Stephen R. Covey)</h3>
								<p>Having a diverse team in terms of gender and culture is essential for us to have a wider and global impact. Our principle is to have equal proportions of male and female team members with respect and acceptance. Further, by keeping physical abilities, racism, and religious beliefs aside, we coexist and flourish in a nurturing environment. Every individual working here is given equal opportunities, equal pay and helps overcome the societal stereotypes.   </p>
						</div>
					</div>
					<div class="testi-holder wow fadeIn" data-wow-delay="0.6s">
						<div class="testi">
								<h3>Employee loyalty begins with employer loyalty.
									<br>– (Harvey Mackay)</h3>
								<p>Without the support of our team, we could not have reached where we are. Our brand thrives on loyalty of its people, be it clients or our employees. Loyalty is not just an ordinary quality; it is a force that drives team members to do their best and perform to the highest standards possible. Further, it is often said that Employee loyalty is infectious. It starts from the top and goes across all sections of our team. Our team takes pride in what they do, and they are committed to give their 100%. </p>
						</div>
					</div>
					<div class="testi-holder wow fadeIn" data-wow-delay="0.9s">
						<div class="testi">
								<h3>Innovation is the only way to win.
									<br>– (Steve Jobs)</h3>
								<p>We promote out of the box thinking at all levels to bring out the best in our people. Creativity is essential to produce new things using imagination and then the unique skill set of our people polishes them further. This is something that has led us to improve customer relationships, increase productivity, provide better service, reduce costs, and build a strong brand together. </p>
						</div>
					</div>
					<img src="assets/images/hr.webp" alt="AAAFx" title="AAAFx" width="250" height="250" class="center-div wow fadeIn fluid-img" data-wow-delay="1.2s" />
				</div>
				<div class="section benefits-sec">
					<div class="content-holder wow fadeInLeft">
						<h3>Rewards and Benefits</h3>
						<br>
						<p class="italic">The reward for work well done is the opportunity to do more. – (Jonas Salk)</p>
						<br>
						<p>Want to spend more time together along with your family, or on your health and fitness? Ready to develop your profession however nonetheless keep a balance? We not only provide our employees remuneration commensurate with industry standards, but also a flexible working environment, lucrative rewards, and various modes of appreciation and recognition.</p>
						<br>

					</div>
					<div class="img-holder wow fadeInRight">
						<img src="assets/images/bene.webp" alt="AAAFx" title="AAAFx" width="560" height="343" class="fluid-img" />
					</div>
				</div>
				<div class="section-small current-vac t-center">
					<div class="content-holder wow fadeIn t-center">
						<h3>Current Vacancies </h3>
						<br>
						<p>Join us to become a part of an outstanding work force, that is driven only and only by excellence and commitment amidst a cohesive team environment. Everyone who works with AAAFx feels valued at work!
						<br><br>
						Learn what it takes to turn out to be one of us and how you can best prepare. We are looking forward to receiving your application and hearing from you. </p>
						<br>
						<div class="title-holder">
							<h6><strong>If you are ready for challenges, We’re ready for you</strong></h6>
						</div>
					</div>
				</div>

			<div class="section-small job-list">
				<div class="wrapper">
					<div class="accordion-holder">
						<h3 v-on:click="Support ^= true" :class="[{active : Support}]">Customer Support Executive, Greece</h3>
						<div class="content" v-show="Support" style="padding: 20px">
							<div class="job-holder">
                  <h5>What the job looks like?</h5>
                  <ul class="listing-check">
                    <li>
                      Providing Clients support on Chat, emails, and phone.
                    </li>
                    <li>
                      Looking into client queries responsibly and pro-actively.
                    </li>
                    <li>
                      Investigating trades of clients, when required.</li>
                    <li>
                      Communicating with clients in a soft, friendly and highly
                      professional manner.
                    </li>
                    <li>
                      Working closely with the management on short-term and
                      long-term goals and objectives.
                    </li>
                    <li>
                      Taking notes of clients’ requests and passing them on
                      pro-actively to the relevant teams.
                    </li>
                    <li>
                      Following up with allied teams, as and when required to
                      deliver excellent service to clients.
                    </li>
                    <li>
                      To ensure highest levels of customer service getting
                      delivered to clients.
                    </li>
                  </ul>
                  <h5>What do we look for?</h5>
                  <ul class="listing-check">
                    <li>
                      Strong presence of mind problem-solving abilities.
                    </li>
                    <li>
                      Excellent Communication Skills.
                    </li>
                    <li>
                      Strong Team Spirit
                    </li>
                    <li>
                      Positive Attitude along with calm and composed behavior.
                    </li>
                    <li>
                      Prior experience in Investment Finance or Customer Support
                      role.
                    </li>
                    <li>
                      Professionalism, honesty and Integrity.
                    </li>
                  </ul>
                </div>
							<div class="apply-btn">
								<a href="mailto:support@aaafx.com" class="btn btn-blue">APPLY FOR THE JOB</a>
							</div>
						</div>
						<h3 v-on:click="Finance ^= true"  :class="[{active : Finance}]">Finance Executive, Greece</h3>
						<div class="content" v-show="Finance">
							<div class="job-holder">
                  <h5>What the job looks like?</h5>
                  <ul class="listing-check">
                    <li>
                      Assisting in making quarterly and annual budgets
                    </li>
                    <li>
                      Managing records and receipts
                    </li>
                    <li>
                      Preparing Budget Vs Actual Analysis and Reports
                    </li>
                    <li>
                      Reconciling daily, monthly, quarterly, and yearly
                      transactions
                    </li>
                    <li>
                      Preparing quarterly Balance sheets
                    </li>
                    <li>
                      Processing invoices</li>
                    <li>
                      Preparing Cash Flow Statements for the Department
                    </li>
                    <li>
                      Being a point of contact for other departments for Finance related matters.
                    </li>
                    <li>
                      Clearing employee reimbursements.
                    </li>
                    <li>
                      Calculating and Publishing Financial Ratios
                    </li>
                    <li>
                      Providing assistance in Audits, as and when required.
                    </li>
                    <li>
                      Brining on board ideas and suggestions for efficient funds management
                    </li>
                  </ul>
                  <h5>What do we look for?</h5>
                  <ul class="listing-check">
                    <li>
                      Passion for Finance & Accounting
                    </li>
                    <li>
                      Full knowledge of ERP and MS Office
                    </li>
                    <li>
                      Accounting Masters and minimum experience of 3 years working with a Global Broker.
                    </li>
                    <li>
                      Strong Analytical Skills
                    </li>
                    <li>
                      Excellent Team Player
                    </li>
                    <li>
                      Positive Attitude
                    </li>
                  </ul>
                </div>
							<div class="apply-btn">
								<a href="mailto:support@aaafx.com" class="btn btn-blue">APPLY FOR THE JOB</a>
							</div>
						</div>
						<h3 v-on:click="Administrator ^= true"  :class="[{active : Administrator}]">Data Administrator, Greece</h3>
						<div class="content" v-show="Administrator">
							<div class="job-holder">
                  <h5>What the job looks like?</h5>
                  <ul class="listing-check">
                    <li>
                      Control access permissions and privileges.
                    </li>
                    <li>
                      Provide support and assistance to Developers.
                    </li>
                    <li>
                      Monitor and maintain system health and security.
                    </li>
                    <li>
                      Design backup processes for server and associated data.
                    </li>
                    <li>
                      Create accounts for all users and assign security levels.
                    </li>
                    <li>
                      Provide end-to-end technical support and problem resolution.
                    </li>
                    <li>
                      Schedule and perform regular server maintenance.
                    </li>
                    <li>
                      Create database management procedures.
                    </li>
                    <li>
                      Prepare statistical information for internal and external
                      use.
                    </li>
                    <li>
                      Evaluate data analysis models and procedures.
                    </li>
                    <li>
                      Participate in company’s cross-training program.
                    </li>
                  </ul>
                  <h5>What do we look for?</h5>
                  <ul class="listing-check">
                    <li>
                      Bachelor’s Degree in Computers.
                    </li>
                    <li>
                      1+ years’ experience in similar role.
                    </li>
                    <li>
                      Advanced knowledge of database structure and theory.
                    </li>
                    <li
                      >Experience with MySQL and MSSQL.
                    </li>
                    <li>
                      Understanding of Database backup procedures.
                    </li>
                    <li>
                      PostgreSQL certification preferred.
                    </li>
                    <li>
                      Ability to work independently with minimal supervision and
                      assistance.
                    </li>
                  </ul>
                </div>
							<div class="apply-btn">
								<a href="mailto:support@aaafx.com" class="btn btn-blue">APPLY FOR THE JOB</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section-small current-vac t-center">
				<div class="content-holder wow fadeIn t-center">
					<h3>How to Apply? </h3>
					<br>
					<p>If you feel you can fit well in any of the above roles, simply click on the ‘Apply’ button and send us your <br>Resume. Our HR Associate will get back to you.</p>

				</div>
			</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data() {
    return {
        Support:false,
        Finance:false,
        Administrator:false,
      }
    }
  }

</script>